import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { MenuItem, Snackbar, Tooltip } from '@material-ui/core'
import { Formik } from 'formik'
import * as Styled from './EpisodeForm.styled'
import { InfoCircledIcon } from './EpisodeForm.styled'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'
import SelectField from '../Fields/SelectField/SelectField'
import DateField from '../Fields/DateField/DateField'
import InputField from '../Fields/InputField/InputField'
import { Episode, Playlist } from 'types'
import { getPodcastPlaylists } from '../../services/api'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import ImageInput from '../Fields/InputField/ImageInput'

interface FormValues {
  title: string
  description: string
  playlistIds: string[]
  visibility: string
  releaseDate?: Date
  audioFile?: File
  imageFile?: File
}

const createEpisodeValidationSchema = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório'),
  description: Yup.string(),
  visibility: Yup.string().required('Campo obrigatório'),
  releaseDate: Yup.string().required('Campo obrigatório'),
  audio: Yup.mixed().required('Campo obrigatório'),
  image: Yup.string().nullable(),
})

interface EpisodeFormProps {
  initialValues: {
    title: string
    description: string
    playlistIds: string[]
    visibility: string
    releaseDate?: Date
    audio?: {
      url?: string
      size?: string
      audioType?: string
    }
    image?: string
  }
  onSubmit: (values: FormValues) => void | Promise<void>
  podcastId: string
  episodeId?: string
  isEdit?: boolean
  episode?: Episode
  uploadImage?: (file: File) => Promise<string>
  uploadAudio?: (file: File) => Promise<string>
}

const EpisodeForm: React.FC<EpisodeFormProps> = ({
  initialValues,
  onSubmit,
  isEdit,
  episode,
  uploadImage,
  uploadAudio,
  podcastId,
}) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>()
  const [podcastPlaylists, setPodcastPlaylist] = useState<Playlist[]>([])

  useEffect(() => {
    if (!isEdit) {
      getPodcastPlaylists(podcastId).then((value) =>
        setPodcastPlaylist(value.data),
      )
    }
  }, [isEdit])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setWaitingForResponse(true)
        await onSubmit(values as FormValues)
        setWaitingForResponse(false)
      }}
      validationSchema={createEpisodeValidationSchema}
    >
      {({
        handleSubmit,
        errors,
        touched,
        values,
        setFieldValue,
        handleBlur,
      }) => {
        return (
          <Styled.FlexForm>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={!!snackbarMessage}
              autoHideDuration={15000}
              onClose={() => setSnackbarMessage(undefined)}
              message={snackbarMessage}
            />
            <Styled.InputWrapper shortWidth={true}>
              <TextFieldField
                placeholder="Nome do Episódio"
                name="title"
                variant="outlined"
                label="Nome do episódio"
                error={!!(touched.title && errors?.title)}
                helperText={touched.title && errors?.title}
                onBlur={handleBlur}
                fullWidth
                value={values.title}
                darkMode
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper shortWidth={true}>
              <TextFieldField
                label="Descrição"
                placeholder="Descrição"
                name="description"
                variant="outlined"
                multiline
                error={!!(touched.description && errors?.description)}
                helperText={touched.description && errors?.description}
                onBlur={handleBlur}
                fullWidth
                value={values.description}
                darkMode
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper shortWidth={true}>
              <Styled.InputWrapperColumn>
                <ImageInput
                  label="Imagem de capa"
                  name="imageFile"
                  variant="outlined"
                  error={!!(touched.description && errors?.description)}
                  helperText={touched.description && errors?.description}
                  onBlur={handleBlur}
                  fullWidth
                  style={{ fontSize: 24 }}
                  darkMode
                  accept="image/*"
                  onChangeFile={async (file) => {
                    await uploadImage(file).then((value) => {
                      if (value) {
                        setFieldValue('image', value)
                      }
                    })
                  }}
                />
                {values.image && <Styled.EpisodeCover src={values.image} />}
              </Styled.InputWrapperColumn>
              <Styled.InputWrapperColumn>
                <InputField
                  label="Arquivo de áudio"
                  name="audioFile"
                  variant="outlined"
                  error={!!(touched.audio && errors?.audio)}
                  helperText={touched.audio && errors?.audio}
                  onBlur={handleBlur}
                  fullWidth
                  style={{ fontSize: 24 }}
                  darkMode
                  accept="audio/*"
                  onChangeFile={async (file) => {
                    await uploadAudio(file).then((value) => {
                      if (value) {
                        setFieldValue('audio', {
                          url: value,
                          size: file.size,
                          audioType: file.type,
                        })
                      }
                    })
                  }}
                  setAudioDuration={async (duration) => {
                    setFieldValue('audio', {
                      ...values.audio,
                      duration: duration,
                    })
                  }}
                  isAudio
                />
                {values.audio && <audio controls src={values.audio?.url} />}
              </Styled.InputWrapperColumn>
            </Styled.InputWrapper>
            <Styled.InputWrapper shortWidth={true}>
              <Styled.Row>
                {!isEdit && (
                  <Styled.Column flex={1}>
                    <SelectField
                      name="playlistIds"
                      variant="outlined"
                      displayEmpty
                      error={
                        !!(
                          touched.playlistIds && (errors?.playlistIds as string)
                        )
                      }
                      helperText={
                        touched.playlistIds && (errors?.playlistIds as string)
                      }
                      onBlur={handleBlur}
                      darkMode
                      fullWidth
                      multiple
                      label="Selecione as listas de reprodução"
                    >
                      {podcastPlaylists?.map((playlist) => (
                        <MenuItem value={playlist.playlistId}>
                          {playlist.title}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Styled.Column>
                )}
                <Styled.Column flex={1}>
                  <SelectField
                    name="visibility"
                    variant="outlined"
                    displayEmpty
                    error={!!(touched.visibility && errors?.visibility)}
                    helperText={touched.visibility && errors?.visibility}
                    onBlur={handleBlur}
                    darkMode
                    fullWidth
                    label="Selecione a visibilidade"
                  >
                    <MenuItem value={'open'}>Aberto para todos</MenuItem>
                    <MenuItem value={'exclusiveToSupporters'}>
                      Exclusivo para apoiadores
                    </MenuItem>
                  </SelectField>
                </Styled.Column>
              </Styled.Row>
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <div style={{ display: 'flex' }}>
                <DateField
                  label="Data de publicação"
                  name="releaseDate"
                  variant="outlined"
                  error={!!(touched.releaseDate && errors?.releaseDate)}
                  helperText={touched.releaseDate && errors?.releaseDate}
                  onBlur={handleBlur}
                  fullWidth
                  darkMode
                ></DateField>
                <Tooltip
                  disableFocusListener
                  title="Se a data selecionada for futura, todos usuários relevantes serão notificados no lançamento do episódio."
                  TransitionProps={{ style: { fontSize: '0.9rem' } }}
                >
                  <InfoCircledIcon />
                </Tooltip>
              </div>
            </Styled.InputWrapper>
            <br />
            <Styled.SubmitButtonWrapper>
              <ButtonShadow
                onPress={() => handleSubmit()}
                disabled={waitingForResponse}
                label={isEdit ? 'SALVAR' : 'PUBLICAR'}
              />
              {waitingForResponse && <Styled.LoadingCircle />}
            </Styled.SubmitButtonWrapper>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default EpisodeForm
