import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { PaymentMethod, PostCreditCard } from 'types'
import CardAndAddressInputs from './CardAndAddressInputs'
import { Colors } from 'styles'
import ReCAPTCHA from 'react-google-recaptcha'

import visa from './../../assets/images/visa.png'
import masterCard from './../../assets/images/mastercard.png'
import amex from './../../assets/images/americanexpress.png'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 3.5rem;
  padding-bottom: 3.5rem;
  gap: 16px;
  align-items: center;

  @media (max-width: 900px) {
    padding-right: 0;
  }
`

const CrediCardNumber = styled.span`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 32px;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
`

const CardLogo = styled.img`
  width: 4rem;
  height: 3rem;
  margin: auto 1rem;
`

const CreditCardListItemWrapper = styled.div<{
  isSelected: boolean
  maxWidth: boolean
}>`
  background: ${Colors.GREY[50]};
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
  padding: 32px 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(2px 2px 8px rgba(33, 36, 39, 0.25));
  border-color: ${({ isSelected }) =>
    isSelected ? Colors.PURPLE[400] : Colors.GREY[200]};
  @media (max-width: 450px) {
    flex-direction: column;
  }
`

const ListItemWrapper = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const FormTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: ${Colors.GREY[400]};
  font-family: SpaceGrotesk;
  text-align: center;
`

const CustomActionButton = styled.div`
  height: 60%;
`

type Props = {
  creditCards: PaymentMethod[]
  onSelectCreditCard?: (creditCard: PaymentMethod) => void
  customHeadlineColor?: string
  hideHeadlineText?: boolean
  noSelection?: boolean
  customAction?: {
    onPress: (paymentMethodId: string) => void
    iconColor: string
    icon: string
  }
  onPayment: (recaptchaToken: string) => void
  onAddCard: (creditCard: PostCreditCard, recaptchaToken: string) => Promise<void>
  value?: string
}

const CreditCards = ({
  creditCards,
  onSelectCreditCard,
  customHeadlineColor,
  hideHeadlineText,
  noSelection,
  customAction,
  onPayment,
  onAddCard,
  value = '0',
}: Props): JSX.Element => {
  const [isAddCardModalVisible, setIsAddCardModalVisible] = useState<boolean>(
    !creditCards?.length,
  )
  const [cardSelectedIndex, setCardSelectedIndex] = useState<number>(
    creditCards?.length > 0 ? creditCards.length - 1 : undefined,
  )
  const [recaptchaToken, setRecaptchaToken] = useState<string>()

  const registeredCardText =
    creditCards?.length === 1 ? 'CARTÃO CADASTRADO' : 'CARTÕES CADASTRADOS'

  const onPressCardItem = (indexCardSelected: number): void => {
    onSelectCreditCard && onSelectCreditCard(creditCards[indexCardSelected])
    setCardSelectedIndex(indexCardSelected)
  }

  useEffect(() => {
    if (creditCards?.length > 0) onPressCardItem(cardSelectedIndex)
  }, [])

  const renderCardFlag = (flag: string): JSX.Element => {
    if (flag?.toLowerCase() === 'visa') return <CardLogo src={visa} />
    if (flag?.toLowerCase() === 'mastercard')
      return <CardLogo src={masterCard} />
    if (flag?.toLowerCase() === 'amex') return <CardLogo src={amex} />
    return (
      <FontAwesomeIcon
        icon={faCreditCard}
        color={Colors.BRAND_SECONDARY}
        name="credit-card"
      />
    )
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: PaymentMethod
    index: number
  }): JSX.Element => {
    if (
      item &&
      cardSelectedIndex !== undefined &&
      cardSelectedIndex !== index
    ) {
      return null
    }
    return (
      <ListItemWrapper>
        <CreditCardListItemWrapper
          onClick={() => onPressCardItem(index)}
          isSelected={noSelection ? false : true}
          maxWidth={!customAction}
        >
          <CrediCardNumber>{`**** **** *** ${item.cardNumber.substring(
            12,
            16,
          )}`}</CrediCardNumber>
          {renderCardFlag(item.flag)}
        </CreditCardListItemWrapper>
        {customAction && (
          <CustomActionButton
            onClick={() => customAction.onPress(item.paymentMethodId)}
          >
            <FontAwesomeIcon
              icon={faCreditCard}
              color={Colors.BRAND_SECONDARY}
              name={customAction.icon}
            />
          </CustomActionButton>
        )}
      </ListItemWrapper>
    )
  }

  if (isAddCardModalVisible) {
    return (
      <Wrapper>
        <FormTitle>Novo cartão</FormTitle>
        <CardAndAddressInputs onAddCard={onAddCard} />
        {creditCards.length > 1 && typeof cardSelectedIndex === 'number' && (
          <ButtonShadow
            label="Escolher outro cartão"
            variant="secondary"
            onPress={() => {
              setIsAddCardModalVisible(false)
            }}
            size="large"
          />
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {creditCards?.map((item, index) => renderItem({ item, index }))}
      {creditCards.length > 1 && typeof cardSelectedIndex === 'number' && (
        <ButtonShadow
          label="Escolher outro cartão"
          variant="secondary"
          onPress={() => setCardSelectedIndex(undefined)}
          size="large"
        />
      )}
      <ButtonShadow
        label="Adicionar outro cartão"
        variant="secondary"
        onPress={() => setIsAddCardModalVisible(true)}
        size="large"
      />
      <ButtonShadow
        label="Confirmar apoio"
        variant="primary"
        size="large"
        onPress={() => onPayment(recaptchaToken)}
      />
      <ReCAPTCHA
        sitekey="6LcGCnopAAAAAE1f7lLmBNhpYJqxY3YxKcLM9P5e"
        onChange={(value) => setRecaptchaToken(value)}
      />
    </Wrapper>
  )
}

export default CreditCards
